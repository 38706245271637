import React from "react";

export const Reviews = (props) => {
  const REVIEWS = [
    {
      id: 1,
      type: "Review",
      name: <b>Review: Blurring the Color Line</b>,
      link: (
        <a
          href="https://1882foundation.org/1882-internship/blurring-the-color-line-by-crystal-kwok-review-by-amanda-young-and-hannah-ku-2/"
          target="_blank" // click to open in new page
          rel="noopener noreferrer" // click to open in new page
        >
          <u>Click here to read</u>
        </a>
      ),
      description:
        '"Where did the Chinese sit on the bus during the era of Jim Crow segregation?"',
      author: "Amanda Young and Hannah Ku",
    },
    {
      id: 2,
      type: "Review",
      name: <b>Review: Turning Red</b>,
      link: (
        <a
          href="https://www.fourkents.com/blog/turning-red-review"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>Click here to read</u>
        </a>
      ),
      description:
        '"This is the first Pixar film to depict uniquely immigrant family issues, like assimilation and filial piety, thanks to Domee Shi’s background."',
      author: "Kent Tong",
    },
    {
      id: 3,
      type: "Review",
      name: <b>Review: Free Food for Millionaires</b>,
      link: (
        <a
          href="https://1882foundation.org/1882-internship/free-food-for-millionaires-by-min-jee-lee-review-by-audrey-sun/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>Click here to read</u>
        </a>
      ),
      description:
        '"What does it mean to be successful? Can you be happy and successful? Min Jin Lee takes a stab at answering these questions, with the backdrop of her home in mind."',
      author: "Audrey Sun",
    },
    {
      id: 4,
      type: "Review",
      name: <b>Review: GRASS</b>,
      link: (
        <a
          href="https://1882foundation.org/1882-internship/grass-by-keum-suk-gendry-kim-review-by-hannah-ku/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>Click here to read</u>
        </a>
      ),
      description:
        '"Grass accomplishes the very difficult task of illustrating violence without sensationalizing or spectacularizing comfort women’s pain."',
      author: "Hannah Ku",
    },
    {
      id: 5,
      type: "Review",
      name: <b>Review: The Poppy War</b>,
      link: (
        <a
          href="https://1882foundation.org/1882-internship/the-poppy-war-by-r-f-kuang-review-by-aida-guo/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>Click here to read</u>
        </a>
      ),
      description:
        '"The Poppy War is a heavy, dark, and unprecedented debut by R.F. Kuang."',
      author: "Aida Guo",
    },
    // {
    //   id: 6,
    //   type: "Review",
    //   name: "Review: Accepted",
    //   link: (
    //     <a
    //       href="https://www.fourkents.com/blog/accepted-review-jubilee-media-tm-landry"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <u>Click here to read</u>
    //     </a>
    //   ),
    //   description:
    //     '"Accepted asks important questions, but it doesn’t have answers to them, which some may consider to be its greatest weakness. But, again, this wasn’t the type of film it set out to be."',
    //   author: "Kent Tong",
    // },
    // {
    //   id: 7,
    //   type: "Review",
    //   name: "Review: Palimpsest: Documents From a Korean Adoption",
    //   link: (
    //     <a
    //       href="https://1882foundation.org/1882-internship/palimpsest-documents-from-a-korean-adoption-by-lisa-wool-rim-sjoblom-review-by-hannah-ku/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <u>Click here to read</u>
    //     </a>
    //   ),
    //   description:
    //     "\"Contrary to this 'clean-slate' narrative, Lisa reveals that the system of adoption is inherently structured by loss and grief.\"",
    //   author: "Hannah Ku",
    // },
    // {
    //   id: 8,
    //   type: "Review",
    //   name: "Review: Water Tossing Boulders",
    //   link: (
    //     <a
    //       href="https://1882foundation.org/talkstory/book-review-water-tossing-boulders/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <u>Click here to read</u>
    //     </a>
    //   ),
    //   description:
    //     '"This is a documentation of the story of Chinese immigrants leading a fight to desegregate a school in Mississippi in the 1920s."',
    //   author: "Stan Lou",
    // },
    // {
    //   id: 9,
    //   type: "Review",
    //   name: "Review: House of Ho",
    //   link: (
    //     <a
    //       href="https://medium.com/oca-national/house-of-ho-review-2476c8d46585"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <u>Click here to read</u>
    //     </a>
    //   ),
    //   description: '"House of Ho is Vietnamese comfort food."',
    //   author: "Kent Tong",
    // },
    // {
    //   id: 10,
    //   type: "Review",
    //   name: "Review: Paper Son",
    //   link: (
    //     <a
    //       href="https://1882foundation.org/literature-corner-blog/paper-son/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <u>Click here to read</u>
    //     </a>
    //   ),
    //   description:
    //     '"The novel deftly weaves into the story an authentic history of the Chinese in Mississippi that gives readers more of an understanding of their presence in this state."',
    //   author: "Jamelah Jacob",
    // },
    // {
    //   id: 11,
    //   type: "Review",
    //   name: "Review: A Little Life",
    //   link: (
    //     <a
    //       href="https://1882foundation.org/literature-corner-blog/a-little-life/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <u>Click here to read</u>
    //     </a>
    //   ),
    //   description:
    //     '"A Little Life contains elements that lie parallel to many Asian American conversations, such as intergenerational trauma, the immigrant experience, and the complexities of male kinship."',
    //   author: "Gabriella Chu",
    // },
    // {
    //   id: 12,
    //   type: "Review",
    //   name: "Review: China Dolls",
    //   link: (
    //     <a
    //       href="https://1882foundation.org/literature-corner-blog/china-dolls/"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       <u>Click here to read</u>
    //     </a>
    //   ),
    //   description: '"Not all China dolls are bound to break."',
    //   author: "Jiajia Zhang",
    // },
  ];
  return (
    <div className="all-reviews">
      <h1>Reviews</h1>
      {REVIEWS.map((review) => (
        <li key={review.id} className="content_element">
          <h3>{review.name}</h3>
          <br />
          <div>Author: {review.author}</div>
          <br />
          <div>{review.description}</div>
          <br />
          <div>{review.link}</div>
        </li>
      ))}
      <h3 className="visit-blog">
        Visit the{" "}
        <u>
          <a
            href="https://1882foundation.org/category/literature-corner-blog/"
            target="_blank" // click to open in new page
            rel="noopener noreferrer" // click to open in new page
          >
            Literature Corner Blog
          </a>
        </u>{" "}
        to browse more reviews and submit your own!
      </h3>
    </div>
  );
};
