import React from "react";

export const SearchBar = ({ searchTerm, setSearchTerm, onSearchClick }) => {
  const handleKeyDown = (event) => {
    // eslint-disable-next-line eqeqeq
    if (event.keyCode === 13) {
      onSearchClick();
    }
  };
  return (
    <div className="box ml-5">
      <input
        className="input"
        type="text"
        placeholder="Search"
        value={searchTerm}
        onKeyDown={handleKeyDown}
        onChange={(event) => setSearchTerm(event.target.value)}
      />
    </div>
  );
};
