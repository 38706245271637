import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faYoutube,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../images/1882logo.png";

export const Header = (props) => {
  const facebook = <FontAwesomeIcon icon={faFacebookF} size="lg" />;
  const instagram = <FontAwesomeIcon icon={faInstagram} size="lg" />;
  const youtube = <FontAwesomeIcon icon={faYoutube} size="lg" />;
  return (
    <div className="banner">
      <div className="inner-banner">
        <a
          href="https://1882foundation.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={logo}
            alt="1882 Foundation Logo"
            className="img-fluid"
            width="198"
          />
        </a>
        <span id="title">Literature & Arts Corner Library</span>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="navbar-end">
            <a href="/" className="navbar-item">
              Home
            </a>
            <a href="/about" className="navbar-item">
              About
            </a>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeKuRaUKoiOsC6WqKelE7ayX1eRFjXq3xioSPq60jdxPhmmFA/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-item"
            >
              Your Suggestions
            </a>
            <a href="/reviews" className="navbar-item">
              Reviews
            </a>
            <a href="/resources" className="navbar-item">
              Other Resources
            </a>
            <a href="/contact" className="navbar-item">
              Contact
            </a>
            <a
              href="https://www.instagram.com/1882foundation/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-item"
            >
              {instagram}
            </a>
            <a
              href="https://www.facebook.com/1882ProjectFoundation/"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-item"
            >
              {facebook}
            </a>
            <a
              href="https://www.youtube.com/channel/UC00NyHsuZW5OpklnE-mLwRw"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-item"
            >
              {youtube}
            </a>
            <a
              href="https://1882foundation.org/projects-3/literature-and-art-corner/"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-item"
            >
              Literature & Arts Corner
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};
