export const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL || "http://localhost:9000/api";

export const DEFAULT_AXIOS_OPTIONS = {
  withCredentials: true,
};

export const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD || "admin";

export const ADMIN_LOGIN_SESSION_STORAGE_KEY =
  process.env.REACT_APP_ADMIN_LOGIN_SESSION_STORAGE_KEY || "LWL53LbiVX3rcx3g";

export const ADMIN_LOGIN_SESSION_STORAGE_VALUE =
  process.env.REACT_APP_ADMIN_LOGIN_SESSION_STORAGE_VALUE || "CLmZb4krKznWv3ui";
