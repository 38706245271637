import React from "react";
import axios from "axios";
import { BASE_API_URL, DEFAULT_AXIOS_OPTIONS } from "../../../AppConstants";

export const ListCreators = ({ creators, setLastChangeOccured }) => {
  const onDeleteClick = (creator) => () => {
    if (window.confirm(`Are you sure that you want to delete?`)) {
      axios
        .delete(`${BASE_API_URL}/creator/${creator.id}`, DEFAULT_AXIOS_OPTIONS)
        .then(() => {
          setLastChangeOccured(new Date());
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };

  return (
    <>
      <h3 className="has-text-left is-size-4">Existing Creators</h3>
      {creators.length > 0 && (
        <table className="table is-bordered is-striped">
          <thead>
            <tr>
              {Object.keys(creators[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {creators.map((creator) => (
              <tr key={creator.id}>
                {Object.values(creator).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
                <td>
                  <button
                    className="button is-small is-danger ml-2"
                    onClick={onDeleteClick(creator)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
