import React from "react";
import { FilterDropdown } from "./FilterDropdown";
import { SearchBar } from "./SearchBar";

export const FilteringMenu = ({
  backgroundOptions,
  mediaTypeOptions,
  genreOptions,
  themeOptions,
  backgroundsSelected,
  setBackgroundsSelected,
  mediasTypesSelected,
  setMediaTypesSelected,
  genresSelected,
  setGenresSelected,
  themesSelected,
  setThemesSelected,
  searchTerm,
  setSearchTerm,
  onSearchClick,
}) => {
  return (
    <div className="filter-menu, has-ratio">
      {/* <h2 className="m-4">Filtering Menu</h2> */}
      <div className="box ml-5">
        {" "}
        {/* outside box */}
        <div class="container">
          <div class="row justify-content-center">
            <div class="col p-2">
              <button
                className="btn offset-md-2 btn-primary search-button" // center button within div, color
                onClick={onSearchClick}
              >
                View Media
              </button>
            </div>
            <div class="col p-2">
              <button
                className="btn offset-md-2 btn-secondary" // center button within div, color
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://1882-foundation-database.netlify.app/";
                }}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
        {/* <button
          className="button is-info col-md-8 offset-md-2" // center button within div
          onClick={onSearchClick}
        >
          Find Media
        </button> */}
        {/* <button
          className="button is-info col-md-8 offset-md-2" // center button within div
          onClick={onSearchClick}
        >
          Find Media
        </button> */}
      </div>
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchClick={onSearchClick}
      />
      <FilterDropdown
        label="Select Media Types"
        options={mediaTypeOptions}
        values={mediasTypesSelected}
        setValue={setMediaTypesSelected}
      />
      <FilterDropdown
        label="Select APIDA Identities"
        options={backgroundOptions}
        values={backgroundsSelected}
        setValue={setBackgroundsSelected}
      />
      <FilterDropdown
        label="Select Genres"
        options={genreOptions}
        values={genresSelected}
        setValue={setGenresSelected}
      />
      <FilterDropdown
        label="Select Themes"
        options={themeOptions}
        values={themesSelected}
        setValue={setThemesSelected}
      />
    </div>
  );
};
