import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_API_URL, DEFAULT_AXIOS_OPTIONS } from "../../../AppConstants";
import { useAdminLoggedIn } from "../../../hooks";
import { AddGenre } from "./AddGenre";
import { ListGenres } from "./ListGenres";
import { UpdateGenre } from "./UpdateGenre";

export const Genre = () => {
  useAdminLoggedIn();

  const [lastChangeOccured, setLastChangeOccured] = useState(new Date());
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_API_URL}/genre`, DEFAULT_AXIOS_OPTIONS)
      .then((res) => {
        setGenres(res.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [lastChangeOccured]);

  return (
    <div>
      <h1>Genres (Admin)</h1>
      <div className="contents">
        <div className="content_element">
          <a href="/admin">Back</a>
        </div>
      </div>
      <div className="content_element">
        <AddGenre setLastChangeOccured={setLastChangeOccured} />
      </div>
      <div className="content_element">
        <UpdateGenre
          genres={genres}
          setLastChangeOccured={setLastChangeOccured}
        />
      </div>
      <div className="content_element">
        <ListGenres
          genres={genres}
          setLastChangeOccured={setLastChangeOccured}
        />
      </div>
    </div>
  );
};
