import React from "react";
// import logo from "../images/1882logo.png";
import librarylogo from "../images/librarylogo-white.png";

export const Footer = (props) => {
  return (
    <div className="footer">
      <div className="banner">
        <div className="inner-banner">
          {/* <a href="https://1882foundation.org/">
            <img
              src={logo}
              alt="1882 Foundation Logo"
              className="has-ratio"
              width="198"
            />
          </a> */}
          <img
            src={librarylogo}
            alt="Literature & Arts Corner Library logo"
            className="img-fluid"
            width="350"
          />
          <div className="lit-footer">Literature & Arts Corner Library</div>
          <a href="/login" className="navbar-item">
            Admin Login
          </a>
        </div>
      </div>
    </div>
  );
};
