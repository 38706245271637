import React from "react";

export const Contact = (props) => {
  return (
    <div>
      <h1>Contact Us</h1>

      <div className="content_element">
        <p>
          <b>Email:</b>
        </p>
        <p>info@1882foundation.org</p>
        <br />
        <p>
          <b>Address:</b>
        </p>
        <p>508 I Street NW (ground level)</p>
        <p>Washington, DC 20001</p>
        <br />
        <p>
          <b>Social Media:</b>
        </p>
        <div className="button_element">
          <a
            href="https://www.instagram.com/1882foundation/?hl=en"
            target="_blank" // click to open in new page
            rel="noopener noreferrer" // click to open in new page
          >
            <u>Instagram</u>
          </a>
        </div>
        <div className="button_element">
          <a
            href="https://www.facebook.com/1882ProjectFoundation"
            target="_blank" // click to open in new page
            rel="noopener noreferrer" // click to open in new page
          >
            <u>Facebook</u>
          </a>
        </div>
        {/* a href="error.html" */}
      </div>
    </div>
  );
};
