import React from "react";
import noc from "../../images/noc.jpeg";
import event from "../../images/event.png";
// import mochi from "../../images/mochi.png";
import kent from "../../images/kent.jpeg";
import aaiff from "../../images/aaiff.jpeg";
import smithapa from "../../images/smithsonianapa.jpeg";

export const Resources = (props) => {
  const RES_LIST = [
    {
      id: 1,
      type: "Blog",
      image: <img src={kent} div className="resource-images" alt="Kent Tong" />,
      name: <b>Four Kents</b>,
      link: (
        <a
          href="https://www.fourkents.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>Click here to visit</u>
        </a>
      ),
      description:
        "Timely commentary and meaningful reviews of films and TV shows. Written by the Literature & Arts Corner Library's media curator.",
    },
    {
      id: 2,
      type: "Organization",
      image: (
        <img
          src={noc}
          div
          className="resource-images img-fluid"
          alt="Nerds of Color logo"
        />
      ),
      name: <b>The Nerds of Color</b>,
      link: (
        <a
          href="https://thenerdsofcolor.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>Click here to visit</u>
        </a>
      ),
      description:
        "A place to discuss, comment on, and geek out about stuff in the world of fandom (i.e., comics, movies, gaming, animation, toys, etc.) through the culturally critical eye of a POC.",
    },
    {
      id: 3,
      type: "Festival",
      image: (
        <img
          src={aaiff}
          div
          className="resource-images img-fluid"
          alt="Asian American International Film Festival logo"
        />
      ),
      name: <b>Asian American International Film Festival</b>,
      link: (
        <a
          href="https://www.aaiff.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>Click here to visit</u>
        </a>
      ),
      description:
        "The United States's first and longest running festival of its kind. AAIFF uses media as a tool for social change and supports diversity and inclusion in the media arts.",
    },
    {
      id: 4,
      type: "Museum",
      image: (
        <img
          src={smithapa}
          div
          className="resource-images img-fluid"
          alt="Smithsonian Asian Pacific American Center logo"
        />
      ),
      name: <b>Smithsonian Asian Pacific American Center</b>,
      link: (
        <a
          href="https://smithsonianapa.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>Click here to visit</u>
        </a>
      ),
      description:
        "A digital museum dedicated to enriching the American Story with the voices of Asian Pacific Americans by sharing history, art, and culture.",
    },
    {
      id: 5,
      type: "Festival",
      image: (
        <img
          src={event}
          div
          className="resource-images"
          alt="Eventbrite logo"
        />
      ),
      name: <b>EventBrite Festivals</b>,
      link: (
        <a
          href="https://www.eventbrite.com/d/online/free--events--this-month/asian-festival"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>Click here to visit</u>
        </a>
      ),
      description:
        "Search for Asian media festivals and related events located near you.",
    },
  ];

  return (
    <div className="all-resources">
      <h1>Other Resources</h1>
      {RES_LIST.map((singleResource) => (
        <li key={singleResource.id} className="content_element">
          <h3>{singleResource.name}</h3>
          <br />
          <div className="resource-content">
            <div className="img-fluid">{singleResource.image}</div>
            <div className="resources-text">
              <div>{singleResource.description}</div>
              <br />
              <div>{singleResource.link}</div>
            </div>
          </div>
        </li>
      ))}
    </div>
  );
};
