import React from "react";
import lauren_jasmine from "../../images/lauren-jasmine.jpg";
import kent_headshot from "../../images/kent-headshot.jpeg";
import ted_headshot from "../../images/ted-headshot.jpeg";
import stan_headshot from "../../images/stan-headshot.jpeg";

export const About = (props) => {
  const ABOUT_LIST = [
    // {
    //   id: 1,
    //   type: "About",
    //   name: "About the Library",
    //   description:
    //     "The Literature & Arts Corner Library compiles recommendations for books, films, music, TV shows, podcasts, poetry, and beyond related to Asian, Pacific Islander, and Desi Americans (APIDA). APIDA communities lack representation within mainstream American media, and they have often faced misleading portrayals of their cultures and experiences. This interactive and curated collection of works promotes cross-cultural appreciation by sharing content that changes up this narrative and exemplifies diversity within the arts",
    //   description_2:
    //     "Visitors are encouraged to submit their personal media recommendations through the suggestions form to help the database grow, as well as browse relevant resources and read featured reviews written by local critics. This database web application crowdsources material in an effort to preserve APIDA stories and creations.",
    // },
    {
      id: 2,
      type: "About",
      image: (
        <img
          src={lauren_jasmine}
          div
          className="resource-images"
          alt="Lauren and Jasmine"
        />
      ),
      name: <b>About the Creators</b>,
      description:
        "Lauren Eng (she/her) is an Associate at the 1882 Foundation, where her role focuses on building the Literature & Arts Corner. After joining the organization as an intern in 2020, she conceptualized the Literature & Arts Corner Library and led its development over two and a half years. She also writes event recaps and articles for the Literature Corner Blog, as well as op-eds for local publications. In addition to her 1882 Foundation work, she is a Data Science Analyst at Dell Technologies and volunteers as a Community Network Leader for the Hydrocephalus Association, a non-profit organization that advocates for people with a brain condition called hydrocephalus. Lauren graduated from the University of Maryland, College Park with a bachelor's degree in Information Science and a double minor in Asian American Studies and Sustainability Studies.",
      description_2:
        "Jasmine Soni (she/her) graduated from the University of Maryland, College Park (UMD) in 2021 with a bachelor’s degree in Information Science specializing in Data Science, and a minor in Creative Poetry Writing. While there, she led the UMD chapter of Active Minds, a mental health awareness student organization, and was a corporate contact on the board of UMD’s Association for Women in Computing. She became involved with the 1882 Foundation through Lauren, when they began building the Literature & Arts Corner Library in a database IT course together in 2020. Jasmine currently works at Bloomberg in a data science position, where she can evolve the IT and programming skills she loved gaining during her time in college, as well as volunteer for organizations for over 75 hours a year in exchange for donations to charities from Bloomberg Philanthropies.",
      description_3:
        "Additional contributors include Dominique Rafael, Brandon Eng, Adedamilotun Kanji-Ojelade, Samantha Tavarez, Alexander Carcamo, and Andrew Cunniff. This project could not have been completed without the support of the University of Maryland College of Information Studies.",
    },
    {
      id: 3,
      type: "About",
      image: (
        <img
          src={kent_headshot}
          div
          className="resource-images img-fluid"
          alt="Kent Tong"
        />
      ),
      name: <b>About the Media Curator</b>,
      description:
        "Kent Tong (he/him) is the Manager of Programs at OCA - Asian Pacific American Advocates, a 501(c)(3) national membership-driven nonprofit based in Washington, D.C. dedicated to advancing the social, political, and economic well-being of Asian Americans and Pacific Islanders. In his role, Kent oversees OCA’s youth programs, including high school and college leadership and advocacy trainings, scholarships, and internships.",
      description_2:
        "Kent was born and raised in the Greater New Orleans Area to Vietnamese refugee parents. An alumnus from Loyola University New Orleans, majoring in Studio Art and minoring in Film Studies, Kent dedicated his time outside the classroom to various Vietnamese-interest organizations. It's through his experiences as President of the Loyola Asian Student Organization, Executive Director of Camp Delta, and President of the Union of Vietnamese Student Associations Gulf Coast where Kent developed a passion in youth development and programming.",
      description_3:
        "Prior to joining OCA, Kent was an Office Admin at Hidrate, Inc., a startup tech company; volunteer staff member of The 2020 Project, a voter engagement nonprofit; Fellow at the Jubilee Project, creating short documentaries; and Disney College Program Cast Member. In his free time he enjoys watching movies in theatres, usually one or two every weekend, and writing reviews on his blog.",
    },
    {
      id: 4,
      type: "About",
      image: (
        <img
          src={ted_headshot}
          div
          className="resource-images img-fluid"
          alt="Ted Gong"
        />
      ),
      name: <b>About the Executive Director</b>,
      description:
        "Ted Gong (he/him) is Executive Director of the 1882 Project Foundation and President of the DC chapter of the Chinese American Citizens Alliance. Before retiring in 2012, Ted was a career diplomat in the U.S. Department of State, where he served primarily in East Asia on policy and operational issues related to border management and security, migration and refugees, and consular affairs. He has degrees in History, Asian Studies, and National Strategic Studies from the University of California, the University of Hawaii, and the U.S. Army War College. Ted is also included in The Guardian‘s The Frederick Douglass 200, a list of two hundred people — abolitionists, diplomats, writers, feminists, and more — who best embody the spirit and work of Frederick Douglass.",
    },
    {
      id: 5,
      type: "About",
      image: (
        <img
          src={stan_headshot}
          div
          className="resource-images"
          alt="Stan Lou"
        />
      ),
      name: <b>About the Literature & Arts Corner Director</b>,
      description:
        "Stan Lou (he/him) was born in Greenville, Mississippi, of immigrant parents from China. Stan earned Bachelor of Science Degree in Civil Engineering from the University of Michigan and had a career with the Federal Aviation Administration before retiring in Washington, DC. He is most proud of his three accomplished children who all reside in California now. Upon retirement, Stan went to China in 2003 to teach English to university students and to learn about himself there for almost three years. He returned to the Washington, DC area, where he has committed himself to become busily engaged with understanding more about his heritage as a Chinese American. With that as his motivation, he has worked with the Asian Pacific American community to improve the quality of life for its members. Most of his focus has been with OCA-Asian Pacific American Advocates, where he has served as co-president of the Greater Washington DC Chapter and the Vice-President for Education & Culture on the OCA National Board. He worked with the 1882 Project and is active with a group that created the Talk Story series that engages the DC community in sharing the stories of their experiences living as APAs. He is also leading the Literature and Arts Corner program.",
    },
  ];

  return (
    <div className="all-resources">
      <h1>About</h1>
      <div className="content_element">
        <h3>
          <b>About the Library</b>
        </h3>
        <br />
        <p>
          The Literature & Arts Corner Library compiles recommendations for
          books, films, music, TV shows, podcasts, poetry, and beyond related to
          Asian, Pacific Islander, and Desi Americans (APIDA). APIDA communities
          lack representation within mainstream American media, and they have
          often faced misleading portrayals of their cultures and experiences.
          This interactive and curated collection of works promotes
          cross-cultural appreciation by sharing content that changes up this
          narrative and exemplifies diversity within the arts.
        </p>
        <br />
        <p>
          Visitors are encouraged to submit their personal media recommendations
          through the suggestions form to help the database grow, as well as
          browse relevant resources and read featured reviews written by local
          critics. This database web application crowdsources material in an
          effort to preserve APIDA stories and creations.
        </p>
      </div>
      {ABOUT_LIST.map((singleAbout) => (
        <li key={singleAbout.id} className="content_element">
          <h3>{singleAbout.name}</h3>
          <br />
          <div className="resource-content">
            <div className="img-fluid">{singleAbout.image}</div>
            <div className="resources-text">
              <div>{singleAbout.description}</div>
              <br />
              <div>{singleAbout.description_2}</div>
              <br />
              <div>{singleAbout.description_3}</div>
            </div>
          </div>
        </li>
      ))}
    </div>
  );
};
