import React from "react";
import Select from "react-select";

export const FilterDropdown = ({ label, options, values, setValue }) => {
  return (
    <div className="box ml-5">
      <p>{label}</p>
      <Select
        value={values}
        onChange={(selectedOptions) => setValue(selectedOptions)}
        options={options.sort(function (a, b) {
          const nameA = a.label; // label is the dropdown options
          const nameB = b.label;
          // display in ascending order
          if (nameA > nameB) {
            return 1; // b is sorted before a
          }
          if (nameA < nameB) {
            return -1; // a is sorted before b
          }
          // names must be equal
          return 0;
        })}
        isMulti
        isClearable={true}
        isSearchable={true}
      />
    </div>
  );
};
